import api from '../instance'

export function fetchGetUsersFromAPI() {
  return api.get('/usersFromAPI')
}

export function fetchGetUsers() {
  return api.get('/users')
}

export function fetchGetUsersByAccessLevel(accessLevel) {
  return api.get(`/users?accessLevel=${accessLevel}`)
}

export function fetchDeleteUser(user) {
  return api.delete(`/users?email=${user}`)
}

export function fetchCreateUser({ name, email, accessLevel, costCenters }) {
  return api.post('/users', {
    name,
    email,
    accessLevel,
    costCenters,
  })
}

export function fetchEditUser({ name, email, accessLevel, costCenters }) {
  return api.put('/users', {
    name,
    email,
    accessLevel,
    costCenters,
  })
}
