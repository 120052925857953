<template>
  <div class="users-page">
    <div class="header-info">
      <div>
        <span>Usuários cadastrados</span><br />
        Gerencie usuários cadastrados no sistema
      </div>
      <div class="header-info__actions">
        <el-input
          class="search-input"
          v-model="search"
          size="medium"
          placeholder="Pesquisar na tabela"
          clearable
          prefix-icon="el-icon-search"
        ></el-input>
        <el-button
          class="primary-button"
          @click="handleCreateUser"
          :loading="loadingBtn"
        >
          Adicionar
        </el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
        size="small"
        class="users-table"
        :data="getPageContent"
        v-loading="loadingTable"
        height="100%"
        max-height="100%"
        @sort-change="handleSortChange"
      >
        <el-table-column
          label="Nome"
          prop="name"
          header-align="left"
          align="left"
          min-width="180"
          sortable
          :formatter="handleTableFormatData"
          show-overflow-tooltip
        />
        <el-table-column
          label="Email"
          prop="email"
          header-align="left"
          align="left"
          min-width="180"
          sortable
          :formatter="handleTableFormatData"
          show-overflow-tooltip
        />
        <el-table-column
          label="Nível de acesso"
          prop="access_level"
          header-align="left"
          align="left"
          min-width="180"
          sortable
          :formatter="handleTableFormatAccessLevel"
          show-overflow-tooltip
        />
        <el-table-column
          label="CC cadastrados"
          prop="cost_centers"
          header-align="left"
          align="left"
          min-width="180"
          sortable
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="handleGetTooltipText(scope.row)"
              placement="top"
              :open-delay="500"
            >
              <span>{{ handleCCText(scope.row) }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          label="Ações"
          prop="email"
          header-align="center"
          align="center"
          min-width="180"
        >
          <template slot-scope="scope">
            <el-tooltip
              content="Excluir usuário"
              placement="left"
              :open-delay="500"
            >
              <el-button
                @click="handleDeleteUser(scope.row.email, scope.$index)"
                icon="el-icon-close"
                class="btn-icon"
              />
            </el-tooltip>
            <el-tooltip
              content="Editar usuário"
              placement="right"
              :open-delay="500"
            >
              <el-button
                @click="handleEdit(scope.row)"
                icon="el-icon-edit"
                class="btn-icon"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginator-container">
      <el-pagination
        class="page-selector"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="sizes, slot, prev, pager, next"
        :page-size.sync="pageSize"
        :page-sizes="getPageSizes"
        :current-page.sync="page"
        :total="tableLength"
      >
        <el-row class="current-page">
          {{ getPaginatorText }}
        </el-row>
      </el-pagination>
    </div>
    <DrawerActions
      :isVisible="drawerVisible"
      :info="targetUser"
      :action="drawerAction"
      :costCenters="costCenters"
      :availableUsers="usersAPI"
      @close="handleCloseDrawer"
      @edit="handleEditUser"
      @create="handleCreateNewUser"
    />
  </div>
</template>

<script>
import {
  fetchGetUsers,
  fetchGetUsersFromAPI,
  fetchDeleteUser,
  fetchCreateUser,
  fetchEditUser,
} from '@/service/users'
import { fetchGetCostCenters } from '@/service/costCenters'
import DrawerActions from './Partials/DrawerActions/DrawerActions.vue'

import { mapGetters } from 'vuex'

export default {
  components: { DrawerActions },
  name: 'UsersPage',
  data() {
    return {
      search: '',
      usersData: [],
      loadingTable: true,
      loadingBtn: true,
      tableLength: 0,
      page: 1,
      pageSize: this.$store.state.pageSize,
      pageInterval: this.$store.state.pageSize,
      searchColumns: [
        { propName: 'email', propType: 'String' },
        { propName: 'name', propType: 'String' },
      ],
      drawerVisible: false,
      drawerAction: 'create',
      targetUser: {},
      costCenters: [],
      contentBuffer: [],
      usersAPI: [],
    }
  },
  methods: {
    handleTBD() {
      this.$message({
        message: 'Em desenvolvimento',
        duration: 5000,
      })
    },
    handleCreateUser() {
      this.targetUser = {}
      this.drawerAction = 'create'
      this.drawerVisible = true
    },
    handleEdit(info) {
      this.targetUser = info
      this.drawerAction = 'edit'
      this.drawerVisible = true
    },
    handleCloseDrawer() {
      this.drawerVisible = false
    },
    handleCurrentChange(newPage) {
      this.page = newPage
    },
    handleSortChange({ column, order, prop }) {
      const orientation = {
        ascending: false,
        descending: true,
      }

      if (order) {
        let filtred = [...this.usersData]
        filtred.sort((a, b) => {
          if (a[prop] < b[prop]) {
            return -1
          }
          if (a[prop] > b[prop]) {
            return 1
          }
          return 0
        })
        if (orientation[order]) {
          filtred.reverse()
        }
        this.contentBuffer = filtred
      } else {
        this.contentBuffer = this.usersData
      }
    },
    handleCCText({ cost_centers, access_level }) {
      if (access_level === 'pmo') {
        return 'Todos os centros de custo'
      } else {
        let text =
          cost_centers.length === 1
            ? `${cost_centers.length} centros de custo cadastrados`
            : `${cost_centers.length} centro de custo cadastrado`

        return text
      }
    },
    handleGetTooltipText({ cost_centers, access_level }) {
      if (access_level === 'pmo') {
        return 'Usuário PMO tem acesso a todos os centros de custo'
      } else {
        let costText = ''
        cost_centers.forEach((costCenter) => {
          costText += `${String(costCenter)}-\n`
        })
        return costText.length > 0
          ? costText
          : 'Não existe centro de custo atribuido a este usuário.'
      }
    },
    handleTableFormatAccessLevel(row, column, cellValue, index) {
      const access = {
        pmo: 'PMO',
        manager: 'GP',
        default: '-',
      }
      return access[cellValue]
    },
    handleTableFormatData(row, column, cellValue, index) {
      return this.handleFormatData(cellValue)
    },
    async handleEditUser({ user }) {
      let loading = this.$loading({
        lock: true,
        text: 'Salvando informações..',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const { data } = await fetchEditUser(user)
        if (data) {
          this.$message({
            type: 'success',
            message: 'Operação realizada com sucesso!',
            duration: 5000,
            showClose: true,
          })
          const index = this.usersData.findIndex(
            (item) => item.email === user.email
          )
          this.$set(this.usersData, index, data)
          this.drawerVisible = false
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.response.data,
          duration: 5000,
          showClose: true,
        })
      } finally {
        loading.close()
      }
    },
    async handleCreateNewUser({ user }) {
      let loading = this.$loading({
        lock: true,
        text: 'Adicionando usuário...',
        background: 'hsla(0,0%,100%,.9)',
      })
      try {
        const { data } = await fetchCreateUser(user)
        if (data) {
          this.$message({
            type: 'success',
            message: 'Operação realizada com sucesso!',
            duration: 5000,
            showClose: true,
          })
          this.handleCurrentPage()
          this.usersData.push(data)
          this.contentBuffer = this.usersData
          this.drawerVisible = false
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.response.data,
          duration: 5000,
          showClose: true,
        })
      } finally {
        loading.close()
      }
    },
    handleCurrentPage() {
      if (this.getPageSize === this.usersData.length) {
        this.getPageSize++
      }
    },
    handleGetPageContent(data) {
      const pageContent = data.slice(
        this.getPageSize * this.page - this.getPageSize,
        this.getPageSize * this.page
      )
      this.tableLength = data.length
      return pageContent
    },
    async handleGetData() {
      try {
        this.loadingTable = true
        const { data } = await fetchGetUsers()
        if (data) {
          this.usersData = data
          this.contentBuffer = data
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        this.loadingTable = false
      }
    },
    async handleGetUsersFromAPI() {
      try {
        this.loadingBtn = true
        const { data } = await fetchGetUsersFromAPI()
        this.usersAPI = data
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      } finally {
        this.loadingBtn = false
      }
    },
    async handleGetCostCenters() {
      try {
        const { data } = await fetchGetCostCenters()
        if (data) {
          this.costCenters = data
        }
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.response.data,
          duration: 5000,
        })
      }
    },
    handleDeleteUser(user, index) {
      this.$confirm(
        `Após a ação, não será possível restaurar o estado anterior.`,
        `Exluir usuário: ${user}?`,
        {
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar',
          type: '',
          confirmButtonClass: 'primary-button',
          cancelButtonClass: 'secondary-button',
        }
      )
        .then(async () => {
          let loading = this.$loading({
            lock: true,
            text: 'Removendo usuário...',
            background: 'hsla(0,0%,100%,.9)',
          })
          try {
            const { data } = await fetchDeleteUser(user)
            if (data) {
              this.usersData.splice(
                index + (this.page - 1) * this.getPageSize,
                1
              )
              this.$message({
                type: 'success',
                message: 'Operação realizada com sucesso!',
                duration: 5000,
                showClose: true,
              })
            }
          } catch (error) {
            this.$message({
              type: 'error',
              message: error.response.data,
              duration: 5000,
              showClose: true,
            })
          } finally {
            loading.close()
          }
        })
        .catch(() => {})
    },
  },
  computed: {
    ...mapGetters(['getPageSize']),
    getPaginatorText() {
      const page = this.page
      const pageSize = this.getPageSize
      const dataSize = this.tableLength
      const start = (page - 1) * pageSize + (dataSize > 0 ? 1 : 0)
      const end =
        page * pageSize > dataSize ? dataSize : (page - 1) * pageSize + pageSize

      return `Mostrando de ${start} até ${end} de ${dataSize} itens.`
    },
    getPageSizes() {
      return this.handlePageSizes(this.pageInterval, this.tableLength)
    },
    getPageContent() {
      const pageContent = this.handlePageContent(
        this.search,
        this.searchColumns,
        this.contentBuffer
      )
      return this.handleGetPageContent(pageContent)
    },
  },
  async created() {
    await Promise.all([
      this.handleGetData(),
      this.handleGetCostCenters(),
      this.handleGetUsersFromAPI(),
    ])
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
