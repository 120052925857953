import api from '../instance'

export function fetchGetCostCenters(month = null) {
  if (month) {
    return api.get(`/costCenters?month=${month}`)
  } else {
    return api.get(`/costCenters`)
  }
}

export function fetchPutCostCenterName(costCenterId, projectName) {
  return api.put(`/costCenters/${costCenterId}`, { projectName })
}

export function findCostCentersByClient(date, costCenterCode) {
  return api.get(
    `/findCostCentersByClient?date=${new Date(
      date
    ).toISOString()}&ccCode=${costCenterCode}`
  )
}

export function resourceTransfer(data) {
  return api.put(`/resourceTransfer`, data)
}
