<template>
  <Drawer
    :visibility="isVisible"
    :drawerName="getDrawerTitle"
    :isBtnDisabled="getBtnStatus"
    @close="handleClose"
    @save="handleSave"
  >
    <div class="drawer-inner">
      <span class="drawer-inner__title">Informações</span>
      <div class="drawer-inner__inputs">
        <el-row class="input-container">
          <span> Usuário </span>
          <el-select
            v-model="user.email"
            :disabled="isUserDisabled"
            filterable
            remote
            :remote-method="filterAvailableUsers"
            placeholder="Digite uma letra para filtrar os usuários"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.email"
              :label="`${item.social_name} (${item.email})`"
              :value="item.email"
            >
            </el-option>
          </el-select>
        </el-row>
        <el-row class="input-container">
          <span> Nível de acesso </span>
          <el-select
            v-model="user.accessLevel"
            placeholder="Selecione o nível de acesso"
            @change="handleChangeAccessLevel"
          >
            <el-option
              v-for="(item, index) in accessLevels"
              :key="`${item.value}${index}`"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-row>
        <el-row class="input-container">
          <span> Centros de custo </span>
          <el-select
            v-model="user.costCenters"
            multiple
            placeholder="Selecione os centros de custo"
            :disabled="getAccessInputControl"
          >
            <el-option
              v-for="(item, index) in costCenters"
              :key="`${item}${index}`"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-row>
      </div>
    </div>
  </Drawer>
</template>

<script>
import Drawer from '@/components/Drawer/Drawer.vue'

export default {
  name: 'DrawerActions',
  data() {
    return {
      user: {
        name: '',
        email: '',
        accessLevel: '',
        costCenters: [],
      },
      options: [],
      loading: false,
      accessLevels: [
        {
          value: 'pmo',
          label: 'PMO',
        },
        {
          value: 'manager',
          label: 'GP',
        },
      ],
      hasEmailError: false,
      emailErrorMessage: '',
      authorizedDomains: process.env.VUE_APP_AUTHORIZED_DOMAINS,
      isUserDisabled: false,
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: () => 'create',
    },
    info: {
      type: Object,
      default: () => {
        return {}
      },
    },
    costCenters: {
      type: Array,
      default: () => {
        return []
      },
    },
    availableUsers: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  components: {
    Drawer,
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleCheckEmail() {
      return this.authorizedDomains.includes(this.user.email.split('@')[1])
    },
    handleClearError() {
      this.hasEmailError = false
    },
    filterAvailableUsers(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.options = this.availableUsers.filter((item, idx) => {
            return `${item.social_name}`
              .toLowerCase()
              .startsWith(String(query)?.toLowerCase())
          })
        }, 200)
      } else {
        this.options = this.availableUsers.slice(0, 99)
      }
    },
    handleSave() {
      if (!this.handleCheckEmail()) {
        this.hasEmailError = true
      } else {
        this.hasEmailError = false
        this.$emit(this.action, {
          user: {
            name: this.availableUsers.find((e) => this.user.email === e.email)
              ?.social_name,
            email: this.user.email,
            accessLevel: this.user.accessLevel,
            costCenters: this.user.costCenters,
          },
        })
      }
    },
    handleErrorMessage() {
      const domains = this.authorizedDomains.split(',')
      let message =
        domains.length === 1
          ? 'Email invalido! Informe um email do seguinte domínio: '
          : 'Email invalido! Informe um email de um dos seguintes domínios: '

      domains.forEach((domain) => {
        message += `${domain}, `
      })

      this.emailErrorMessage = message
    },
    handleChangeAccessLevel() {
      if (this.user.accessLevel === 'pmo') {
        this.user.costCenters = []
      }
    },
  },
  computed: {
    getDrawerTitle() {
      return this.action === 'edit'
        ? 'Editar usuário'
        : 'Adicionar novo usuário'
    },
    getBtnStatus() {
      let status = true
      status =
        this.user.email.length > 0 && this.user.accessLevel.length > 0
          ? false
          : true
      if (
        this.user.accessLevel !== 'pmo' &&
        this.user.costCenters.length <= 0
      ) {
        status = true
      }
      return status
    },
    getAccessInputControl() {
      return this.user.accessLevel === 'pmo' ? true : false
    },
  },
  watch: {
    action() {
      this.isUserDisabled = this.action === 'edit'
    },
    info() {
      this.user = {
        name: this.info?.name || '',
        email: this.info?.email || '',
        accessLevel: this.info?.access_level || '',
        costCenters: this.info?.cost_centers || '',
      }
    },
  },
  created() {
    this.options = this.availableUsers.slice(0, 99)
    this.handleErrorMessage()
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
